import { useEffect } from 'react'
import useAuthActions from 'store/actions/auth'
import { useAuth } from 'store/selectors'
import Box from '@mui/material/Box'
import Button from './Button'
import PhoneLoginForm from './PhoneLoginForm'

const LOGOUT_ENABLED = true

const Login = () => {
  const {
    checkAuth,
    signInWithExistingUser,
    // signInWithGoogle,
    signInWithPhone,
    verifyCode,
    signOut,
  } = useAuthActions()
  const { user, authChecked, isLoading, error, codeSent } = useAuth()

  useEffect(() => {
    if (!authChecked) checkAuth()
  }, [authChecked, checkAuth])

  if (!authChecked) return null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        position: 'relative',
      }}
    >
      {/* <Button sx={{ mb: 4 }} onClick={signInWithGoogle}>Sign In With Google</Button> */}
      <PhoneLoginForm
        signIn={signInWithPhone}
        verifyCode={verifyCode}
        isLoading={isLoading}
        error={error}
        codeSent={codeSent}
      />
      {user && (
        <Button onClick={signInWithExistingUser}>
          Continue as {user.phoneNumber}
        </Button>
      )}
      {LOGOUT_ENABLED && user && (
        <Button
          sx={{ position: 'absolute', bottom: '20px', left: '20px' }}
          onClick={signOut}
        >
          log out
        </Button>
      )}
    </Box>
  )
}

export default Login
