import { useState, useEffect } from 'react'
import useAuthActions from 'store/actions/auth'
import Box from '@mui/material/Box'
import logo from 'assets/logos/logo_white.png'
import Input from './Input'
import Button from './Button'

const RECAPTCHA_ID = 'recaptcha-container'

const PhoneLoginForm = ({ signIn, verifyCode, error, isLoading, codeSent }) => {
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const { setRecaptcha } = useAuthActions()

  useEffect(() => {
    setRecaptcha(RECAPTCHA_ID)
  }, [setRecaptcha])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: '20px',
        position: 'relative',
        height: '400px',
        '& img': {
          position: 'absolute',
          top: '10px',
          width: '60px',
        },
      }}
    >
      <Box id={RECAPTCHA_ID} />
      <img src={logo} alt="logo" />
      <Box>
        {codeSent ? (
          <Input
            value={code}
            onChange={setCode}
            onSubmit={() => verifyCode(code)}
            belowText={error || 'enter code'}
            isLoading={isLoading}
          />
        ) : (
          <Input
            value={phone}
            onChange={setPhone}
            onSubmit={() => signIn(phone)}
            adornment="+"
            belowText={error || 'enter phone number with country code'}
            isLoading={isLoading}
          />
        )}
      </Box>
      <Box sx={{ position: 'absolute', bottom: '10px' }}>
        {codeSent && <Button onClick={() => signIn(phone)}>resend code</Button>}
      </Box>
    </Box>
  )
}

export default PhoneLoginForm
