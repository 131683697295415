import { useSelector } from 'react-redux'
// import { createSelector } from 'reselect'

////////////// SIMPLE /////////////

export const auth = (state) => state.auth
export const toaster = (state) => state.toaster
export const settings = (state) => state.settings
export const modals = (state) => state.modals

//////////// USE SELECTOR /////////

export const useAuth = () => useSelector(auth)
export const useToaster = () => useSelector(toaster)
export const useSettings = () => useSelector(settings)
export const useModals = () => useSelector(modals)

///// PARAMATERIZED USE SELECTOR ///

export const useModal = (modalId) =>
  useModals()[modalId] || { isOpen: false, params: {} }
