import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import { reducer as auth } from './actions/auth'
import { reducer as settings } from './actions/settings'
import { reducer as toaster } from './actions/toaster'
import { reducer as modals } from './actions/modals'

const appReducer = combineReducers({
  auth,
  settings,
  toaster,
  modals,
})

const store = createStore(
  appReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

export default store
