import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithPhoneNumber,
  signInWithPopup,
  RecaptchaVerifier,
  GoogleAuthProvider,
} from 'firebase/auth'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import queryString from 'query-string'
import useActions from 'hooks/useActions'

/////////////////////// SETUP /////////////////////

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

window.FIREBASE_APPCHECK_DEBUG_TOKEN =
  process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
})

const auth = getAuth(app)

const { error, ...config } = queryString.parse(window.location.search)

let phoneConfirmationResult = null

async function loadUser() {
  return new Promise((resolve) => {
    const unlisten = auth.onAuthStateChanged((user) => {
      unlisten()
      resolve(user)
    })
  })
}

// TODO: figure out what domain to put instead of '*'
function handleUser(user) {
  const data = {
    token_type: 'bearer',
    access_token: user.accessToken,
    refresh_token: user.stsTokenManager.refreshToken,
    user: btoa(JSON.stringify(user)),
    state: config.state,
  }

  if (config.redirect_uri)
    window.location = `${config.redirect_uri}?${queryString.stringify(data)}`
  else window.top.postMessage(data, '*')
}

export function getRecaptcha(recaptchaId) {
  return new RecaptchaVerifier(auth, recaptchaId, { size: 'invisible' })
}

function toDigits(str) {
  return str.replace(/[^\d]/g, '')
}

/////////////////////// TYPES ////////////////////

export const types = {
  CHECK_AUTH: 'auth/CHECK_AUTH',
  SIGN_IN_WITH_EXISTING_USER: 'auth/SIGN_IN_WITH_EXISTING_USER',
  SIGN_IN_WITH_GOOGLE_PENDING: 'auth/SIGN_IN_WITH_GOOGLE_PENDING',
  SIGN_IN_WITH_GOOGLE_SUCCESS: 'auth/SIGN_IN_WITH_GOOGLE_SUCCESS',
  SIGN_IN_WITH_GOOGLE_FAILURE: 'auth/SIGN_IN_WITH_GOOGLE_FAILURE',
  SET_RECAPTCHA: 'auth/SET_RECAPTCHA',
  SIGN_IN_WITH_PHONE_PENDING: 'auth/SIGN_IN_WITH_PHONE_PENDING',
  SIGN_IN_WITH_PHONE_SUCCESS: 'auth/SIGN_IN_WITH_PHONE_SUCCESS',
  SIGN_IN_WITH_PHONE_FAILURE: 'auth/SIGN_IN_WITH_PHONE_FAILURE',
  VERIFY_CODE_PENDING: 'auth/VERIFY_CODE_PENDING',
  VERIFY_CODE_SUCCESS: 'auth/VERIFY_CODE_SUCCESS',
  VERIFY_CODE_FAILURE: 'auth/VERIFY_CODE_FAILURE',
  SIGN_OUT: 'auth/SIGN_OUT',
}

/////////////////////// ACTIONS ///////////////////

const checkAuth = () => {
  return async (dispatch, getState) => {
    if (config.logout_on_load) await auth.signOut()

    const user = await loadUser()

    if (user && getState().auth.config.auto_redirect) handleUser(user)
    else
      return dispatch({
        type: types.CHECK_AUTH,
        data: user,
      })
  }
}

const signInWithExistingUser = () => {
  return (dispatch, getState) => {
    console.log('signing in')
    handleUser(getState().auth.user)
    dispatch({ type: types.SIGN_IN_WITH_EXISTING_USER })
  }
}

const signInWithGoogle = () => {
  return async (dispatch) => {
    dispatch({ type: types.SIGN_IN_WITH_GOOGLE_PENDING })
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider())
      handleUser(result.user)
    } catch (error) {
      dispatch({
        type: types.SIGN_IN_WITH_GOOGLE_FAILURE,
        data: error.message,
      })
    }
  }
}

const setRecaptcha = (recaptchaId) => {
  window.recaptchaVerifier = getRecaptcha(recaptchaId)
  return { type: types.SET_RECAPTCHA }
}

const signInWithPhone = (phone) => {
  return async (dispatch) => {
    dispatch({ type: types.SIGN_IN_WITH_PHONE_PENDING })
    try {
      phoneConfirmationResult = await signInWithPhoneNumber(
        auth,
        `+${toDigits(phone)}`,
        window.recaptchaVerifier
      )
      dispatch({ type: types.SIGN_IN_WITH_PHONE_SUCCESS })
    } catch (error) {
      console.log('sign in error', error)
      dispatch({
        type: types.SIGN_IN_WITH_PHONE_FAILURE,
        data: error.message.includes('invalid-phone-number')
          ? 'phone number invalid, please try again'
          : error.message,
      })
    }
  }
}

const verifyCode = (code) => {
  return async (dispatch) => {
    dispatch({ type: types.VERIFY_CODE_PENDING })
    try {
      const result = await phoneConfirmationResult.confirm(toDigits(code))
      handleUser(result.user)
    } catch (error) {
      dispatch({
        type: types.VERIFY_CODE_FAILURE,
        data: error.message.includes('invalid-verification-code')
          ? 'code invalid, please try again'
          : error.message,
      })
    }
  }
}

const signOut = () => {
  return async (dispatch) => {
    await auth.signOut()
    dispatch({ type: types.SIGN_OUT })
  }
}

export default useActions.bind(null, {
  checkAuth,
  signInWithExistingUser,
  signInWithGoogle,
  setRecaptcha,
  signInWithPhone,
  verifyCode,
  signOut,
})

const initialState = {
  config,
  error: error || null,
  authChecked: false,
  codeSent: false,
  user: null,
  isLoading: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_AUTH:
      return {
        ...state,
        user: action.data,
        authChecked: true,
      }

    case types.SIGN_IN_WITH_GOOGLE_FAILURE:
      return {
        ...state,
        error: action.data,
      }

    case types.SIGN_IN_WITH_PHONE_PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      }

    case types.SIGN_IN_WITH_PHONE_SUCCESS:
      return {
        ...state,
        codeSent: true,
        error: null,
        isLoading: false,
      }

    case types.SIGN_IN_WITH_PHONE_FAILURE:
      return {
        ...state,
        error: action.data,
        isLoading: false,
      }

    case types.VERIFY_CODE_PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      }

    case types.VERIFY_CODE_FAILURE:
      return {
        ...state,
        error: action.data,
        isLoading: false,
      }

    case types.SIGN_OUT:
      return {
        ...state,
        user: null,
      }

    default:
      return state
  }
}
