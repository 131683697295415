import { useEffect } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const Input = ({
  value,
  onChange,
  onSubmit,
  belowText,
  adornment,
  isLoading,
}) => {
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        onSubmit()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [onSubmit])

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottomColor: 'text.primary',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          fontSize: '24px',
          lineHeight: '30px',
          pb: 1,
          '& input': {
            backgroundColor: 'transparent',
            outline: 0,
            border: 0,
            color: 'text.primary',
            width: 250,
            ml: 1,
            fontSize: '24px',
            pb: 0,
            lineHeight: '30px',
          },
        }}
      >
        <Box>{adornment}</Box>
        <input
          autoFocus
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          textAlign: 'center',
          top: 'calc(100% + 10px)',
          fontSize: '0.8em',
        }}
      >
        {isLoading ? (
          <CircularProgress size={20} sx={{ color: 'text.primary' }} />
        ) : (
          belowText
        )}
      </Box>
    </Box>
  )
}

export default Input
