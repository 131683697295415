import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Login from './Login'

const Auth = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default Auth
