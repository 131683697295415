import Box from '@mui/material/Box'

const Button = ({ children, onClick, sx }) => (
  <Box
    sx={{
      ...sx,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
      fontSize: '0.9em',
    }}
    onClick={onClick}
  >
    {children}
  </Box>
)

export default Button
